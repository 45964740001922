import { Doc } from "../convex/_generated/dataModel";

// A "user" document with only select fields. This is stored in `localStorage` in the user's
// browser/device to enable child <-> parent switching.
type PartialUser = Pick<Doc<"users">, "_id" | "avatar" | "email" | "first_name" | "isKid" | "isParent">;

export function getStoredGuardian(): PartialUser | null {
  const guardianJson = localStorage.getItem("parent");
  let guardian: PartialUser | null;
  if (guardianJson == null) {
    guardian = null;
  } else {
    try {
      guardian = {
        // added 2024-03-08 - localStorage before then did not include `isKid` or `isParent`. this
        // is used by `<GuardianPicker>` for styling but does not affect authentication so is safe
        // to hard-code here. can eventually be removed after existing sessions dead, maybe 2mo?
        isKid: false,
        isParent: true,
        ...JSON.parse(guardianJson),
      };
    } catch (_error) {
      guardian = null;
    }
  }
  return guardian;
}

// add getStoredGuardianSecretLoginUrl

export function getStoredGuardianSecret(): string | null {
  const guardianSecretLoginUrl = getStoredGuardianSecretLoginUrl();
  if (!guardianSecretLoginUrl) {
    return null;
  }
  return guardianSecretLoginUrl.split("/u/")[1];
}

export function getStoredGuardianSecretLoginUrl(): string | null {
  console.log("getStoredGuardianSecretLoginUrl");
  return localStorage.getItem("parentSecretLink");
}

export function setStoredGuardianSecretLoginUrl(url: string) {
  console.log("setStoredGuardianSecretLoginUrl", url);
  localStorage.setItem("parentSecretLink", url);
}

export function setDevicePrimaryUserType(type: "child" | "guardian") {
  localStorage.setItem("devicePrimaryUserType", type);
}
export function getDevicePrimaryUserType(): "child" | "guardian" {
  // return "guardian";
  const stored = localStorage.getItem("devicePrimaryUserType") as "child" | "guardian";
  return stored || "child";
}
